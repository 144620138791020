import variantColorEnum from '@/enum/variantColor';
import { ReactNode } from 'react';

type ITextProps = {
  children: ReactNode;
  className?: string;
  style?: object;
  variant?: variantColorEnum;
  align?: 'left' | 'center' | 'right';
};

const Text = ({
  children,
  className = '',
  style = {},
  variant = variantColorEnum.primary,
  align = 'left'
}: ITextProps) => (
  <span
    className={`flex text-sm text-${variant} pt-1 w-full text-${align} font-medium font-pravalerText ${className}`}
    style={style}
  >
    {children}
  </span>
);

export default Text;
