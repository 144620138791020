export interface IConfirmNewPasswordDto {
  newPassword: string;
  confirmNewPassword: string;
  token: string;
}

type DataConfirmNewPasswordType = IConfirmNewPasswordDto;

export class ConfirmNewPasswordDto implements IConfirmNewPasswordDto {
  newPassword: string;
  confirmNewPassword: string;
  token: string;

  constructor(data: DataConfirmNewPasswordType) {
    this.newPassword = data.newPassword ?? '';
    this.confirmNewPassword = data.confirmNewPassword ?? '';
    this.token = data.token ?? '';
  }
}
