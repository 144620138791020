import { CSSProperties, ReactNode } from 'react';

export const variantClass = {
  secondary:
    'flex items-center justify-center w-full h-[48px] font-pravalerText text-white bg-orange-600 rounded-lg border-2 border-orange-600 hover:bg-orange-700 disabled:bg-gray-300 disabled:border-gray-300 transition duration-200 cursor-pointer disabled:cursor-default p-4 mb-3 text-center font-medium uppercase text-sm gap-2',
  outlined:
    'flex items-center justify-center w-full text-orange-600 font-pravalerText bg-white rounded-lg border-2 border-orange-600 hover:border-orange-800 disabled:border-gray-300 disabled:text-gray-400 transition duration-200 cursor-pointer disabled:cursor-default p-4 mb-4 text-center font-medium uppercase text-sm'
};

type buttonComponentType = {
  children: ReactNode;
  disabled?: boolean;
  onClick?: Function;
  dataOutline?: boolean;
  dataSelected?: boolean;
  dataSmall?: boolean;
  className?: string;
  style?: CSSProperties;
  dataTestId?: string;
  ariaLabel?: string;
  id?: string;
  title?: string;
  variant?: string;
};

const ButtonComponent = ({
  children,
  disabled = false,
  onClick = () => {},
  dataOutline = false,
  dataSelected = false,
  dataSmall = false,
  className = '',
  style = {},
  title = '',
  dataTestId = '',
  ariaLabel = '',
  id = '',
  variant = ''
}: buttonComponentType) => (
  <button
    disabled={disabled}
    type="button"
    onClick={() => onClick()}
    title={title}
    data-outline={dataOutline}
    className={`${className} focus:shadow-none ${variant ?? ''}`}
    style={style}
    data-small={dataSmall}
    data-testid={dataTestId}
    data-selected={dataSelected}
    aria-label={ariaLabel}
    id={id}
  >
    {children}
  </button>
);

export default ButtonComponent;
