import { stringToCpfMask } from '@/helpers/mask';

export const inputValidator = {
  email: {
    test: (value: string) => value.includes('@') && Boolean(value.match(/^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i)),
    message: 'E-mail inválido',
    handler: (value: string) => value.toLowerCase()
  },
  username: {
    test: (value: string) =>
      value.length >= 3 &&
      Boolean(value.match(/[A-z0-9]+\.[A-z0-9]+$/g)) &&
      !value.replace(/[.-]/g, '').match(/^[0-9]+$/i) &&
      !value.includes('@'),
    message: 'Usuário inválido',
    handler: (value: string) => value
  },
  document: {
    test: (value: string) => {
      const clearValue = value.replace(/[.-]/g, '');
      return clearValue.length >= 6 && Boolean(clearValue.match(/^[0-9]{3,14}$/));
    },
    message: 'CPF inválido',
    handler: stringToCpfMask
  }
};

export function fieldTypeInput(field: string) {
  if (field.includes('@')) {
    return 'email';
  }
  if (field.replace(/[.-]/g, '').match(/^[0-9]+$/i)) {
    return 'document';
  }
  return 'username';
}
