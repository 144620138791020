import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { RootState } from '@/core/store';
import { createSelector } from '@reduxjs/toolkit';
import { IUserLogged, setActiveRole, setPermissions, setUserLogged, UserType } from '@/features/userLogged/slices';
import { IPermissionsFactory } from '@/factories/auth/PermissionsFactory';

type UseUserLoggedType = {
  user: UserType;
  token: string;
  permissions: IPermissionsFactory[];
  activeRole: IPermissionsFactory;
  isAnimaRobot: boolean;
  setUserLogged: (params: IUserLogged) => void;
  setPermissions: (params: IPermissionsFactory[]) => void;
  changeActiveRole: (params: IPermissionsFactory) => void;
};
const selectUserLogged = (state: RootState) => state.features.userLoggedFeature;

const userLoggedSelector = createSelector(selectUserLogged, (state) => state);

export const useUserLogged = (): UseUserLoggedType => {
  const dispatch = useAppDispatch();
  const { user, token, permissions, activeRole, isAnimaRobot } = useAppSelector(userLoggedSelector);

  return {
    user,
    token,
    permissions,
    activeRole,
    isAnimaRobot,
    changeActiveRole: (params: IPermissionsFactory) => dispatch(setActiveRole(params)),
    setUserLogged: (params: IUserLogged) => dispatch(setUserLogged(params)),
    setPermissions: (params: IPermissionsFactory[]) => dispatch(setPermissions(params))
  };
};
