import { fieldTypeInput } from '@/helpers/validators/inputValidator';

interface IAuthDto {
  login: string;
  password: string;
}

type LoginType = 'username' | 'email' | 'document';

export type SignInDataType = {
  username: string;
  password: string;
};

export class AuthDto implements IAuthDto {
  login: string;
  password: string;
  type: LoginType;

  constructor(auth: SignInDataType) {
    this.type = fieldTypeInput(auth.username);
    this.password = auth.password;
    if (this.type === 'document') {
      this.login = auth.username.replace(/\D/g, '');
    } else {
      this.login = auth.username;
    }
  }
}
