import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import LoginComponent from '@/screens/Login';
import { COOKIE_MAIN_PORTAL } from '@/constants/globalVars';
import { getCookie } from '@/shared/cookie';
import { NEXT_PUBLIC_INSIGHTS_SCREEN_IS_ENABLED } from '@/constants/envs';

const Index = () => <LoginComponent />;

export const getServerSideProps: GetServerSideProps = async ({ req, res }: GetServerSidePropsContext) => {
  const token = getCookie(COOKIE_MAIN_PORTAL, { req, res });

  if (token) {
    return {
      redirect: {
        destination: NEXT_PUBLIC_INSIGHTS_SCREEN_IS_ENABLED ? '/insights' : '/dashboard',
        permanent: false
      }
    };
  }

  return {
    props: {}
  };
};

export default Index;
