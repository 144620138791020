import { routesWithExtraRoutes } from '@/layouts/SidebarMenu/RouteScreensModel';
import { EMPTY_STRING } from '@/constants/globalVars';
import { menuItensWithExtraOptions } from '@/layouts/SidebarMenu/menuItens';
import { routeScreensModelType } from '@/interfaces/routeScreensModelType';

export function findFirstParentFromPath(path: string): string {
  const findParent: routeScreensModelType | undefined = routesWithExtraRoutes.find((item) => item.routeName === path);
  if (findParent?.childFrom && menuItensWithExtraOptions[findParent.childFrom]?.link !== EMPTY_STRING) {
    return findFirstParentFromPath(menuItensWithExtraOptions[findParent.childFrom]?.link ?? EMPTY_STRING);
  }

  if (!findParent) {
    return path ?? EMPTY_STRING;
  }

  return menuItensWithExtraOptions[findParent.screenName]?.link ?? EMPTY_STRING;
}
