import { FormattedMessage, FormattedMessageKeyType } from '@/shared/translation';
import { expiredAccessAndReturnMessage } from '@/errors/expiredAccessAndReturnMessage';
import { specialTypesOfErrors } from '@/errors/errorMapping';
import { ReactNode } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

export * from './errorMapping';

type getInfoFromRequestErrorType = {
  statusCode: number;
  errorResponseData: string;
};
const getInfoFromRequestError = (error: any): getInfoFromRequestErrorType => {
  const statusCode = error?.response?.status ?? 0;
  const errorResponseData = error?.response?.data ?? '';

  const errorResponseComplete: string = JSON.stringify(errorResponseData);

  return {
    statusCode,
    errorResponseData: errorResponseComplete
  };
};

function getSpecialErrorMessage(errorResponseData: string) {
  const defaultFunctions = {
    [specialTypesOfErrors.accessExpired]: () => expiredAccessAndReturnMessage(),
    [specialTypesOfErrors.apiReturnsAnErrorMessage]: () => errorResponseData
  };

  return { defaultFunctions };
}

type ErrorMappingType = {
  [key: string | number]: ErrorMappingType | string | FormattedMessageKeyType;
};

export type mapErrorsType = ErrorMappingType;
type errorAndKeyType = {
  error: AxiosError | string | AxiosResponse;
  otherKey?: string | number;
};

export const getHttpErrorMessage = ({ error, otherKey = '' }: errorAndKeyType, mapErrors: mapErrorsType): ReactNode => {
  const { statusCode, errorResponseData } = getInfoFromRequestError(error);
  const keyMessage: FormattedMessageKeyType = (mapErrors[statusCode] ||
    mapErrors[otherKey] ||
    mapErrors.default) as FormattedMessageKeyType;

  const { defaultFunctions } = getSpecialErrorMessage(errorResponseData);
  const specialFunction = defaultFunctions[keyMessage as keyof typeof specialTypesOfErrors];
  if (specialFunction) {
    return specialFunction();
  }

  return <FormattedMessage id={keyMessage} />;
};
