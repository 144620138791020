import Image from 'next/image';

export const FiscalDocumentsIcon = () => (
  <div className="!shrink-0 block min-w-[19px] select-none mt-1.5">
    <Image
      draggable={false}
      className="!shrink-0 block min-w-[19px] select-none"
      src="/images/fiscal-documents-screen.svg"
      alt="Menu Documentos Fiscais"
      width="19"
      height="18"
    />
  </div>
);
