import ApiUserAuth from '@/http/ApiUserAuth';
import { AxiosError } from 'axios';
import { STATUS_CODE_UNAUTHORIZED, UNPROCESSABLE_ENTITY } from '@/constants/statusCode';
import { FormattedMessageKeyType } from '@/shared/translation';
import { IConfirmNewPasswordDto } from '@/dto/ConfirmNewPasswordDto';
import { IRecoverPassword } from '@/dto/RecoverPasswordDto';

export default class UserService {
  private static baseUrl = '/forgot-password';
  static validateLogin = (data: IRecoverPassword): Promise<string> =>
    ApiUserAuth.post(this.baseUrl, { ...data }).then(() => 'Fluxo de recuperação de senha iniciado');

  static validateToken = (token: string): Promise<FormattedMessageKeyType> =>
    ApiUserAuth.get(`${this.baseUrl}/check-token/${token}`)
      .then(() => 'text.recoverPassword.tokenValidatedWithSuccess' as FormattedMessageKeyType)
      .catch((error: AxiosError) => {
        if (error.response?.status === UNPROCESSABLE_ENTITY) {
          throw new Error('text.recoverPassword.invalidToken' as FormattedMessageKeyType);
        }
        throw new Error('text.recoverPassword.erroToValidateToken' as FormattedMessageKeyType);
      });

  static confirmPasswordChange = (data: IConfirmNewPasswordDto): Promise<FormattedMessageKeyType> =>
    ApiUserAuth.put(`${this.baseUrl}/confirm-new-password`, data)
      .then(() => 'text.recoverPassword.success' as FormattedMessageKeyType)
      .catch((error: AxiosError) => {
        if (error.response?.status === STATUS_CODE_UNAUTHORIZED) {
          throw new Error('text.recoverPassword.invalidToken' as FormattedMessageKeyType);
        }
        throw new Error('text.recoverPassword.erroToChangePassword' as FormattedMessageKeyType);
      });
}
