import Axios from 'axios';
import { STATUS_CODE_UNAUTHORIZED } from '@/constants/statusCode';
import Router from 'next/router';
import { routesNames } from '@/constants/routesNames';
import { destroyCookie } from '@/shared/cookie';
import { COOKIE_MAIN_PORTAL } from '@/constants/globalVars';
import { handleErrorInRequest } from '@/handlers/errors/handleErrorInRequest';
import { AxiosErrorWithStartTime } from '@/handlers/errors/types';

export function verifyFailedResponse(error: AxiosErrorWithStartTime, apiName: string) {
  const isUnauthorizedError = error.response?.status === STATUS_CODE_UNAUTHORIZED;
  if (isUnauthorizedError) {
    destroyCookie({ cookieName: COOKIE_MAIN_PORTAL, path: '/' });
    Router.push({
      pathname: routesNames.loginPortal,
      query: { redirectUrl: Router.pathname, logout: 'expired' }
    });
  }

  const requestIsCanceledByUser = Axios.isCancel(error);
  if (!requestIsCanceledByUser && !isUnauthorizedError) {
    handleErrorInRequest(error, apiName);
  }

  return Promise.reject(error);
}
