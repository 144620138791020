import { ReactNode } from 'react';
import Container from '@/base/Container';
import Form from '@/base/Form';
import Title from '@/base/Title';
import Image from 'next/image';
import { FormattedMessage, useFormattedMessage, FormattedMessageKeyType } from '@/shared/translation';
import Text from '@/base/Text';

type LoginProps = {
  onSubmit: Function;
  handleSubmit: Function;
  children: ReactNode;
  title: FormattedMessageKeyType;
  subtitle: FormattedMessageKeyType;
};

const Login = ({ onSubmit, handleSubmit, children, title, subtitle }: LoginProps) => {
  const intl = useFormattedMessage();

  return (
    <Container className="flex flex-col md:flex-row md:items-center min-h-screen bg-no-repeat bg-white">
      <div
        className="pl-10 mb-5 pb-4 md:pl-0 md:absolute md:left-14 md:top-20 z-5 md:!bg-none bg-contain"
        style={{ backgroundImage: 'url(/images/bg-purple.png)' }}
      >
        <Image
          src="/images/pravaler-white.svg"
          width="218"
          height="97"
          title={intl.formatMessage({ id: 'alt.logo' })}
          alt={intl.formatMessage({ id: 'alt.logo' })}
          aria-label="site-pravaler"
          id="site-pravaler"
          className="scale-80 md:scale-105 ml-2 mt-4 md:mt-0"
        />
        <Text className="text-white text-3xl mb-4 font-medium font-pravalerDisplay md:ml-0 ml-[1.6rem]">
          Portal da Escola
        </Text>
      </div>

      <div
        className="w-[60%] h-screen hidden bg-cover md:block"
        style={{ backgroundImage: 'url(/images/bg-login.webp)' }}
      />

      <div className="md:min-w-[420px] w-[90%] mx-auto md:w-[40%] md:px-6 py-6 md:py-0 flex">
        <div className="mx-auto text-left bg-white transition duration-300 ease-in rounded-lg">
          <Form method="POST" onSubmit={handleSubmit(onSubmit)} className="max-w-[20rem] m-4">
            <Title Tag="h1" variant="bigTitle" className="mb-4 leading-10">
              <FormattedMessage id={title} />
            </Title>
            <Title Tag="h2" variant="description" className="mb-6">
              <FormattedMessage id={subtitle} />
            </Title>
            {children}
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default Login;
