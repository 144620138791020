import { useMemo } from 'react';
import loginSchema from '@/schemas/login.schema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export type loginFormFields = {
  username: string;
  password: string;
};

export default function useRegisterLoginForm() {
  const schemaMemo = useMemo(() => loginSchema, []);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting }
  } = useForm<loginFormFields>({
    resolver: yupResolver(schemaMemo),
    mode: 'all',
    reValidateMode: 'onSubmit'
  });

  return {
    handleSubmit,
    control,
    errors,
    isValid,
    isSubmitting
  };
}
