import { INPUT_CANNOT_BE_EMPTY, INPUT_IS_TEXT } from '@/constants/messages';
import * as yup from 'yup';
import { fieldTypeInput, inputValidator } from '@/helpers/validators/inputValidator';

const loginSchema = yup.object().shape({
  username: yup
    .string()
    .min(3, 'Mínimo de 3 dígitos.')
    .typeError(INPUT_IS_TEXT)
    .required(INPUT_CANNOT_BE_EMPTY)
    .test('username', 'Campo inválido', (value, context) => {
      if (value) {
        let response = false;

        Object.keys(inputValidator).forEach((key) => {
          response = response || !!inputValidator[key].test(value);
        });
        if (!response) {
          const errorMessage = inputValidator[fieldTypeInput(value)]?.message;
          return context.createError({
            message: errorMessage || 'Campo inválido',
            path: context.path
          });
        }
        return response;
      }
      return true;
    }),
  password: yup.string().min(3, 'Mínimo de 3 dígitos.').typeError('O campo é uma senha').required(INPUT_CANNOT_BE_EMPTY)
});

export default loginSchema;
