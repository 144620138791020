import { HTMLAttributes } from 'react';

interface IForm extends HTMLAttributes<HTMLFormElement> {
  method: 'POST' | 'GET';
  autocomplete?: boolean;
}

const Form = ({ method, autocomplete = true, onSubmit, children, className }: IForm) => (
  <form method={method} onSubmit={onSubmit} autoComplete={autocomplete ? 'on' : 'off'} className={className}>
    {children}
  </form>
);

export default Form;
