import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface propsInterface {
  value?: string;
  className?: string;
  children?: ReactNode;
  variant?: 'bigTitle' | 'title' | 'subtitle' | 'description' | 'none';
  Tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const Title = ({ Tag = 'h1', value = '', className = '', children = '', variant = 'title' }: propsInterface) => {
  const titleStyle = {
    bigTitle: 'text-4xl text-redB2b font-medium leading-snug',
    title: 'text-2xl text-primary font-bold',
    subtitle: 'text-base text-subtitleGray font-semibold',
    description: 'text-base text-descriptionText font-normal leading-relaxed',
    none: ''
  };

  return (
    <Tag
      className={twMerge(
        `${titleStyle[variant]} flex-1 m-0 tracking-normal leading-5 font-pravalerDisplay ${className}`
      )}
    >
      {value || children}
    </Tag>
  );
};

export default Title;
