import { CSSProperties } from 'react';

type buttonComponentType = {
  children: any;
  disabled?: boolean;
  onClick?: Function;
  dataOutline?: boolean;
  dataSelected?: boolean;
  id?: string;
  dataSmall?: boolean;
  className?: string;
  style?: CSSProperties;
  dataTestId?: string;
  ariaLabel?: string;
  variant?: 'round' | 'lg';
};

const ButtonComponentSubmit = ({
  children,
  disabled = false,
  onClick = () => {},
  dataOutline = false,
  dataSelected = false,
  dataSmall = false,
  className = '',
  style = {},
  id = '',
  dataTestId = '',
  ariaLabel = '',
  variant = 'round'
}: buttonComponentType) => {
  const roudedStyle = {
    round: 'rounded-3xl',
    lg: 'rounded-lg'
  };

  return (
    <button
      disabled={disabled}
      type="submit"
      onClick={() => onClick()}
      data-outline={dataOutline}
      className={`${className} disabled:border-gray-300 disabled:border-2 disabled:bg-white disabled:text-gray-300 ${roudedStyle[variant]}`}
      style={style}
      data-small={dataSmall}
      data-testid={dataTestId}
      data-selected={dataSelected}
      aria-label={ariaLabel}
      id={id}
    >
      {children}
    </button>
  );
};

export default ButtonComponentSubmit;
