import { useEffect, useState } from 'react';

export default function useClicked(ref) {
  const [wasClickedOutside, setWasClickedOutside] = useState<boolean>(false);
  const [wasClickedInside, setWasClickedInside] = useState<boolean>(false);

  useEffect(() => {
    function handleClickOutside(event) {
      const existsReference = ref.current;
      const actualReferencesContainsTargetClicked = !ref?.current?.contains(event.target);
      const wasClickedOutsideReference = existsReference && actualReferencesContainsTargetClicked;
      const wasClickedInsideReference = existsReference && !actualReferencesContainsTargetClicked;
      setWasClickedOutside(wasClickedOutsideReference);
      setWasClickedInside(wasClickedInsideReference);
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return { wasClickedOutside, wasClickedInside };
}
