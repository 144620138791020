import { destroyCookie, ISetCookie, setCookie } from '@/shared/cookie';
import { COOKIE_MAIN_PORTAL, SESSION_PORTAL_PROFILE, TIME_LIMIT_COOKIE } from '@/constants/globalVars';
import { ILoginFactoryData } from '@/factories/auth/loginFactory';
import AuthService from '@/services/AuthService';
import { ChangePasswordDto } from '@/dto/ChangePasswordDto';
import { IAlert } from '@/base/Alert';
import { FormattedMessageKeyType } from '@/shared/translation';
import { ConfirmNewPasswordDto } from '@/dto/ConfirmNewPasswordDto';
import { AlertEnum } from '@/enum/AlertEnum';
import { IPermissionsFactory } from '@/factories/auth/PermissionsFactory';
import { destroySession, setSession } from '@/shared/session';
import UserService from '@/services/UserService';

type SignInResponse = {
  user: ILoginFactoryData;
  permissions: IPermissionsFactory[];
  document: string;
  isAnimaRobot: boolean;
};

type DataRecoverPasswordType = {
  token: string;
  newPassword: string;
  confirmNewPassword: string;
};

type SignInParamsType = {
  username: string;
  password: string;
};

export default class AuthProvider {
  static async signIn({ username, password }: SignInParamsType): Promise<SignInResponse> {
    const userDataByToken = await AuthService.loginUser({
      username,
      password
    });

    const newCookieTokenAuth: ISetCookie = {
      cookieName: COOKIE_MAIN_PORTAL,
      value: userDataByToken.token,
      maxAgeInSeconds: TIME_LIMIT_COOKIE,
      path: '/'
    };

    setCookie(newCookieTokenAuth);

    const { permissions, document, isAnimaRobot } = await AuthService.getUserDataByToken();

    setSession(SESSION_PORTAL_PROFILE, btoa(permissions[0].role));

    return {
      user: userDataByToken,
      permissions,
      document,
      isAnimaRobot
    };
  }

  static signOut() {
    destroyCookie({ cookieName: COOKIE_MAIN_PORTAL, path: '/' });
    destroySession(SESSION_PORTAL_PROFILE);
  }

  static changePassword(data: ChangePasswordDto): Promise<IAlert> {
    return AuthService.changePassword(data);
  }

  static recoverPassword(data: DataRecoverPasswordType): Promise<IAlert> {
    const body = new ConfirmNewPasswordDto(data);
    return UserService.confirmPasswordChange(body)
      .then((response) => ({
        message: response as FormattedMessageKeyType,
        type: AlertEnum.success
      }))
      .catch((error: Error) => ({
        message: error.message as FormattedMessageKeyType,
        type: AlertEnum.error
      }));
  }
}
