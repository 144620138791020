import variantColorEnum from '@/enum/variantColor';
import { twMerge } from 'tailwind-merge';

type LoadingType = {
  size?: string;
  variant?: variantColorEnum;
  className?: string;
};

const Loading = ({ size = '', variant = variantColorEnum.secondary, className = '' }: LoadingType) => {
  const style = size === '' ? {} : { width: size, height: size };
  const styleColor = {
    [variantColorEnum.primary]: 'border-t-primary',
    [variantColorEnum.secondary]: 'border-t-secondary'
  };

  return (
    <div className={`flex items-center justify-center w-full ${className}`}>
      <div
        className={twMerge(
          `rounded-full w-[40px] h-[40px] m-auto border-10 border-gray-300 border-t-10 ${styleColor[variant]} animate-spin`
        )}
        style={style}
      >
        <span className="hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Loading;
