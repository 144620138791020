// resolver depois
/* eslint-disable max-params */
import * as Sentry from '@sentry/nextjs';
import { NEXT_PUBLIC_ENABLE_SENTRY } from '@/constants/envs';
import { LogService } from '@/services/LogService';
import { getConfigData, getErroInfo, getMetaInformation, getResponseError } from '@/handlers/errors/utils';
import { AxiosErrorWithStartTime } from '@/handlers/errors/types';
import { STATUS_CODE_NOT_MODIFIED } from '@/constants/statusCode';
import { convertStringToObject } from '@/helpers/utils';

const shouldBeIgnoreErrors = (
  method: string,
  url: string,
  code: string | undefined,
  errorMessage: string,
  status: number,
  responseData: any
): boolean => {
  const message = errorMessage.toLowerCase();

  const isClientSideError = code === 'ERR_NETWORK' && message === 'network error' && status === 0;
  const isAbortedRequest = code === 'ECONNABORTED' && message === 'request aborted' && status === 0;
  const isCache = status === STATUS_CODE_NOT_MODIFIED;

  const responseDataObject = convertStringToObject<any>(responseData, {});
  if (
    status === 400 &&
    responseDataObject?.errors?.status === 'O status atual não permite que o aluno seja revertido.' &&
    String(url).match(/^\/v1\/api-pravaler\/students\/\d{1,}\/revert-to-new-assessment$/)
  ) {
    return true;
  }

  if (
    status === 422 &&
    String(responseDataObject?.message).includes('A quantidade de mensalidades aceitas por essa IES é entre 0 e 0.') &&
    String(url).includes('/v1/api-pravaler/assessments/evaluate/renewal')
  ) {
    return true;
  }

  if (
    method === 'PUT' &&
    status === 400 &&
    String(url).includes('/v1/api-pravaler/assessments/evaluate/renewal') &&
    String(responseDataObject?.message).includes('O novo curso deve estar ativo. HASH:')
  ) {
    return true;
  }

  return isCache || isAbortedRequest || isClientSideError;
};

export const handleErrorInRequest = async (error: AxiosErrorWithStartTime, apiName: string): Promise<void> => {
  if (!NEXT_PUBLIC_ENABLE_SENTRY) {
    return;
  }

  const notHasInformation = !error?.response && !error?.request && !error?.config;
  if (notHasInformation) {
    LogService.logError(error);
    return;
  }

  const response = getResponseError(error);
  const errorInfo = getErroInfo(error);
  const config = getConfigData(error);
  const metadata = getMetaInformation(error);

  if (
    shouldBeIgnoreErrors(config.method, config.url, errorInfo.code, errorInfo.message, response.status, response.data)
  ) {
    return;
  }

  const fingerprint = [config.method, config.url, response.status?.toString() || '0', errorInfo.message];

  Sentry.withScope((scope) => {
    scope.setTag('type', 'requestError');
    scope.setTag('apiName', apiName);

    scope.setExtra('errorCode', errorInfo.code);
    scope.setExtra('errorMessage', errorInfo.message);

    scope.setExtra('metadata', metadata);

    scope.setExtra('configData', config.data);
    scope.setExtra('configHeaders', config.headers);
    scope.setExtra('configMethod', config.method);
    scope.setExtra('configParams', config.params);
    scope.setExtra('configUrl', config.url);

    scope.setExtra('responseData', response.data);
    scope.setExtra('responseHeaders', response.headers);
    scope.setExtra('responseStatus', response.status);

    scope.setFingerprint(fingerprint);

    const errorMessageOnlyDifferentFromDefault = errorInfo.message.includes('request failed with status code')
      ? ''
      : ` as message ${errorInfo.message}`;

    LogService.logError(
      new Error(
        `Request Error ${config.method} ${config.url} -> ${response.status}${errorMessageOnlyDifferentFromDefault} ${response.status}`
      )
    );
  });
};
