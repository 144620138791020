enum variantColorEnum {
  transparent = 'transparent',
  current = 'currentColor',
  primary = '[#232F3B]',
  secondary = '[#FA5300]',
  warning = '[#ffbc00]',
  success = '[#00d397]',
  danger = '[#DC2525]',
  white = '[#ffffff]',
  black = '[#000000]',
  turquoise = '[#16d7c4]',
  darkGreen = '[#155724]',
  gray400 = '[#BDBDBD]',
  gray500 = '[#9E9E9E]',
  gray600 = '[#757575]',
  gray700 = '[#616161]',
  gray800 = '[#424242]',
  lightGrayBg = '[#f1f1f1]',
  lightGrayHover = '[#E3E3E3]',
  grayHover = '[#BBBBBB]',
  lightGrayBorder = '[#dadada]',
  subtitleGray = '[#808080]',
  grayText = '[#5a5a5a]',
  primaryHover = '[#5c7b99]',
  descriptionText = '[#3C3C3C]',
  redB2b = '[#471B28]',
  purpleB2b = '[#352239]',
  greenB2b = '[#02393D]',
  blueB2b = '[#202F3D]',
  yellowB2b = '[#663E04]',
  redPastel = '[#FFE8E2]',
  purplePastel = '[#F3E8FF]',
  greenPastel = '[#CBF9CA]',
  bluePastel = '[#D1E2FC]',
  yellowPastel = '[#FFF5B3]'
}

export default variantColorEnum;
