import { AxiosErrorWithStartTime } from '@/handlers/errors/types';
import { convertObjectToString } from '@/helpers/utils';
import { AxiosError, AxiosRequestConfig } from 'axios';

const getIsConnected = (): boolean | null => {
  try {
    return navigator.onLine;
  } catch (error: unknown) {
    return null;
  }
};

const getUserAgent = () => {
  try {
    return navigator.userAgent.toString();
  } catch (error: unknown) {
    return null;
  }
};

const getDurationRequest = (error: AxiosErrorWithStartTime): number | null => {
  try {
    const dateOnStartRequest = error?.config?.metadata?.startTime;
    if (dateOnStartRequest) {
      return new Date().getTime() - dateOnStartRequest.getTime();
    }
    return null;
  } catch (err: unknown) {
    return null;
  }
};

const getScreenSize = () => {
  try {
    const { innerWidth, innerHeight } = window;
    return {
      innerWidth: innerWidth || 0,
      innerHeight: innerHeight || 0
    };
  } catch (error: unknown) {
    return null;
  }
};

export const getMetaInformation = (error: AxiosErrorWithStartTime) => {
  const isOnline = getIsConnected();
  const userAgent = getUserAgent();
  const sizes = getScreenSize();
  const durationRequest = getDurationRequest(error);

  return {
    isOnline,
    userAgent,
    sizes,
    durationRequest
  };
};

export const getResponseError = (error: AxiosError) => {
  let status = 0;
  let data = null;
  let headers = null;

  if (error?.response) {
    status = error?.response?.status || 0;
    data = convertObjectToString(error?.response?.data);
    headers = convertObjectToString(error?.response?.headers);
  }

  return {
    data,
    status,
    headers
  };
};

const hiddenAuthorizationFromHeader = (headers: AxiosRequestConfig['headers']) => {
  if ('Authorization' in headers) {
    return { ...headers, Authorization: undefined };
  }

  return headers;
};

export const getConfigData = (error: AxiosError) => {
  const url = error?.config?.url || '';
  const method = error?.config?.method?.toUpperCase() || 'UNKNOWN';
  const headers = convertObjectToString(hiddenAuthorizationFromHeader(error?.config?.headers || {}));
  const data = convertObjectToString(error?.config?.data || {});
  const params = convertObjectToString(error?.config?.params || {});

  return {
    url,
    method,
    headers,
    data,
    params
  };
};

export const getErroInfo = (error: AxiosError) => {
  const code = convertObjectToString(error?.code);
  const message = convertObjectToString(error?.message || '').toLowerCase();

  return {
    code,
    message
  };
};
