import Router from 'next/router';
import { FormattedMessage } from '@/shared/translation';
import AuthProvider from '@/core/providers/AuthProvider';

export const expiredAccessAndReturnMessage = () => {
  AuthProvider.signOut();
  Router.reload();

  return <FormattedMessage id="error.expiredAccess" />;
};
