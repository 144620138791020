import Router from 'next/router';
import { AuthDto, SignInDataType } from '@/dto/AuthDto';
import LoginFactory, { ILoginFactoryData } from '@/factories/auth/loginFactory';
import { AxiosError } from 'axios';
import { destroyCookie } from '@/shared/cookie';
import { COOKIE_MAIN_PORTAL } from '@/constants/globalVars';
import { routesNames } from '@/constants/routesNames';
import ApiUserAuth from '@/core/http/ApiUserAuth';
import { ApiResponseType } from '@/core/http/ApiPravaler';
import { ChangePasswordDto } from '@/dto/ChangePasswordDto';
import { IAlert } from '@/base/Alert';
import { AlertEnum } from '@/enum/AlertEnum';
import DataUserFactory, { IDataUserFactory } from '@/factories/auth/dataUserFactory';

export default class AuthService {
  static async loginUser(data: SignInDataType): Promise<ILoginFactoryData> {
    return ApiUserAuth.post(`/login`, new AuthDto(data)).then((response: ApiResponseType) =>
      LoginFactory.builder(response.data)
    );
  }

  static async changePassword(data: ChangePasswordDto): Promise<IAlert> {
    return ApiUserAuth.put(`/user/change-password`, data)
      .then(() => ({
        message: 'Senha alterada com sucesso',
        type: AlertEnum.success
      }))
      .catch(() => ({
        message: 'Erro ao alterar senha',
        type: AlertEnum.error
      }));
  }

  static async getUserDataByToken(): Promise<IDataUserFactory> {
    return ApiUserAuth.get(`/user/profile`)
      .then((response: ApiResponseType) => DataUserFactory.builder(response.data))
      .catch((error: AxiosError) => {
        destroyCookie({ cookieName: COOKIE_MAIN_PORTAL, path: '/' });
        Router.replace(routesNames.loginPortal);
        throw new Error('Error on get permissions by roles', {
          cause: error.cause
        });
      });
  }
}
