import PermissionsFactory, { IPermissionsFactory } from '@/factories/auth/PermissionsFactory';
import { IRoleApi } from '@/interfaces/auth/IRoleApi';

interface Hierarchy {
  id: string;
  userId: string;
  hierarchyId: string;
}

type DataUserApiType = {
  idIAM: string;
  idDB: string;
  displayName: string;
  document: string;
  login: string;
  email: string;
  emailVerified: boolean;
  schools: string[];
  hierarchies: Hierarchy[];
  viewHierarchies: Hierarchy[];
  roles: IRoleApi[];
  active: boolean;
  disabled: boolean;
  createdAtDB: string;
  updatedAtDB: string;
  softDeletedAtDB?: Date;
};

export interface IDataUserFactory {
  email: string;
  name: string;
  login: string;
  document: string;
  isAnimaRobot?: boolean;
  permissions: IPermissionsFactory[];
}

export default class DataUserFactory {
  static builder(data: DataUserApiType): IDataUserFactory {
    return {
      email: data.email,
      login: data.login,
      name: data.displayName,
      document: data.document,
      isAnimaRobot: /^rob[o-Ô] [a-Â]nima/gi.test(data.roles?.[0]?.name.toLowerCase()),
      permissions: PermissionsFactory.builder(data.roles)
    };
  }
}
