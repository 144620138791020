import { ReactNode } from 'react';
import { FormattedMessage, FormattedMessageKeyType } from '@/shared/translation';
import {
  QuestionIcon,
  FolderUserFillIcon,
  SearchStudentsIcon,
  FinancialIcon,
  AntecipationIcon,
  HistoricIcon,
  DashboardIcon,
  UserGraduateIcon,
  AvaliationIcon,
  SpreadsheetIcon,
  TaxIcon,
  TransferCancelIcon,
  SecondTransferIcon,
  IndividualApprovalIcon,
  DownRightIcon,
  ContractIcon,
  CalendarIcon,
  FileIcon,
  DocFileIcon
} from '@/shared/icons';
import { routesNames } from '@/constants/routesNames';
import { ScreenNamesEnum } from '@/interfaces/ISidebarMenu';
import { IconType } from 'react-icons';
import { SimulationIcon } from '@/shared/icons/SimulationIcon';
import { FiscalDocumentsIcon } from '@/shared/icons/FiscalDocumentsIcon';

function mergeBaseToKey(key: string): FormattedMessageKeyType {
  return `menu.${key}` as FormattedMessageKeyType;
}

type ScreenMenuTypeToData = Exclude<
  ScreenNamesEnum,
  | ScreenNamesEnum.loginPortal
  | ScreenNamesEnum.empty
  | ScreenNamesEnum.myAccount
  | ScreenNamesEnum.userManagement
  | ScreenNamesEnum.createUser
  | ScreenNamesEnum.helpdeskSearch
  | ScreenNamesEnum.detailsStudent
  | ScreenNamesEnum.calendar
  | ScreenNamesEnum.documents
  // | ScreenNamesEnum.dashboard
  // | ScreenNamesEnum.insights
>;

type dataMenuType = {
  [name in ScreenMenuTypeToData]: {
    link: string;
    title: ReactNode;
    icon?: ReactNode | IconType;
    childFrom?: ScreenNamesEnum;
  };
};

const dataMenu: dataMenuType = {
  [ScreenNamesEnum.dashboard]: {
    link: routesNames.dashboard,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.dashboard)} />,
    icon: DashboardIcon
  },
  [ScreenNamesEnum.insights]: {
    link: routesNames.insights,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.insights)} />,
    icon: DashboardIcon
  },

  financial: {
    link: routesNames.empty,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.financial)} />,
    icon: FinancialIcon
  },

  [ScreenNamesEnum.financialAntecipation]: {
    link: routesNames.empty,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.financialAntecipation)} />,
    icon: AntecipationIcon,
    childFrom: ScreenNamesEnum.financial
  },

  [ScreenNamesEnum.antecipationExtract]: {
    link: routesNames.antecipationExtract,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.antecipationExtract)} />,
    childFrom: ScreenNamesEnum.financialAntecipation,
    icon: DownRightIcon
  },

  simulation: {
    link: routesNames.simulator,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.simulation)} />,
    icon: SimulationIcon
  },

  approval: {
    link: routesNames.empty,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.approval)} />,
    icon: AvaliationIcon
  },

  approvalBatch: {
    link: routesNames.approvalBatch,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.approvalBatch)} />,
    icon: SpreadsheetIcon,
    childFrom: ScreenNamesEnum.approval
  },

  individualApproval: {
    link: routesNames.individualApproval,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.individualApproval)} />,
    icon: IndividualApprovalIcon,
    childFrom: ScreenNamesEnum.approval
  },

  [ScreenNamesEnum.faq]: {
    link: routesNames.faq,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.faq)} />,
    icon: QuestionIcon
  },

  woReport: {
    link: routesNames.woReport,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.woReport)} />,
    childFrom: ScreenNamesEnum.financialManagement,
    icon: DownRightIcon
  },

  transferEstimate: {
    link: routesNames.transferEstimate,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.transferEstimate)} />,
    childFrom: ScreenNamesEnum.financialManagement,
    icon: DownRightIcon
  },

  students: {
    link: routesNames.empty,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.students)} />,
    icon: UserGraduateIcon
  },

  [ScreenNamesEnum.contractEstimate]: {
    link: routesNames.contractEstimate,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.contractEstimate)} />,
    childFrom: ScreenNamesEnum.students,
    icon: ContractIcon
  },

  transferCancellation: {
    link: routesNames.empty,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.transferCancellation)} />,
    icon: TransferCancelIcon
  },

  [ScreenNamesEnum.studentsWallet]: {
    link: routesNames.studentsWallet,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.studentsWallet)} />,
    icon: FolderUserFillIcon,
    childFrom: ScreenNamesEnum.students
  },

  [ScreenNamesEnum.avaliationHistory]: {
    link: routesNames.avaliationHistory,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.avaliationHistory)} />,
    icon: HistoricIcon,
    childFrom: ScreenNamesEnum.students
  },

  [ScreenNamesEnum.searchStudents]: {
    link: routesNames.searchStudents,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.searchStudents)} />,
    icon: SearchStudentsIcon
  },

  [ScreenNamesEnum.financialManagement]: {
    link: routesNames.empty,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.financialManagement)} />,
    icon: FiscalDocumentsIcon,
    childFrom: ScreenNamesEnum.financial
  },

  [ScreenNamesEnum.transferExtractManagement]: {
    link: routesNames.transferExtractManagement,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.transferExtractManagement)} />,
    childFrom: ScreenNamesEnum.financialManagement,
    icon: DownRightIcon
  },

  [ScreenNamesEnum.operationalsDates]: {
    link: routesNames.operationalsDates,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.operationalsDates)} />,
    childFrom: ScreenNamesEnum.financial,
    icon: CalendarIcon
  },

  [ScreenNamesEnum.fiscalDocuments]: {
    link: routesNames.fiscalDocuments,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.fiscalDocuments)} />,
    childFrom: ScreenNamesEnum.financial,
    icon: TaxIcon
  },

  [ScreenNamesEnum.secondTransfer]: {
    link: routesNames.secondTransfer,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.secondTransfer)} />,
    icon: SecondTransferIcon,
    childFrom: ScreenNamesEnum.transferCancellation
  },

  easyInstallment: {
    link: routesNames.empty,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.easyInstallment)} />,
    childFrom: ScreenNamesEnum.financial,
    icon: FileIcon
  },

  [ScreenNamesEnum.easyInstallmentExtract]: {
    link: routesNames.easyInstallmentExtract,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.easyInstallmentExtract)} />,
    icon: DownRightIcon,
    childFrom: ScreenNamesEnum.easyInstallment
  },

  [ScreenNamesEnum.easyInstallmentEstimate]: {
    link: routesNames.easyInstallmentEstimate,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.easyInstallmentEstimate)} />,
    icon: DownRightIcon,
    childFrom: ScreenNamesEnum.easyInstallment
  },

  grantedManagement: {
    link: routesNames.empty,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.grantedManagement)} />,
    childFrom: ScreenNamesEnum.financial,
    icon: DocFileIcon
  },

  [ScreenNamesEnum.grantedManagementExtract]: {
    link: routesNames.grantedManagementExtract,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.grantedManagementExtract)} />,
    icon: DownRightIcon,
    childFrom: ScreenNamesEnum.grantedManagement
  },

  [ScreenNamesEnum.grantedManagementEstimate]: {
    link: routesNames.grantedManagementEstimate,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.grantedManagementEstimate)} />,
    icon: DownRightIcon,
    childFrom: ScreenNamesEnum.grantedManagement
  }
};

export default dataMenu;

export const menuItensWithExtraOptions = {
  ...dataMenu,
  [ScreenNamesEnum.detailsStudent]: {
    link: routesNames.detailsStudent,
    title: <FormattedMessage id={mergeBaseToKey(ScreenNamesEnum.detailsStudent)} />,
    icon: FolderUserFillIcon,
    childFrom: ScreenNamesEnum.students
  }
};
