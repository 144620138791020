import { ClaimsType } from '@/interfaces/IJwtDecodedType';
import decodeToken from '@/helpers/auth/decodeToken';

type DataApiType = {
  accessToken: string;
  accessTokenApiPravaler: string;
};

export interface ILoginFactoryData {
  data: {
    name: string;
    email: string;
  };
  claims: ClaimsType;
  token: string;
  tokenApiPravaler: string;
}

export default class LoginFactory {
  static builder(data: DataApiType): ILoginFactoryData {
    try {
      const tokenData = decodeToken(data.accessToken);
      return {
        data: {
          name: tokenData?.name ?? '',
          email: tokenData.email
        },
        claims: tokenData?.claims,
        token: data.accessToken,
        tokenApiPravaler: data.accessTokenApiPravaler
      };
    } catch (err: unknown) {
      throw new Error('Error on LoginFactory');
    }
  }
}
