import Axios, { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { API_VERSION_1 } from '@/constants/apiVersion';
import { getCookie } from '@/shared/cookie';
import { COOKIE_MAIN_PORTAL } from '@/constants/globalVars';
import { verifyFailedResponse } from '@/middlewares/verifyFailedResponse';

const configAxios = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_PORTAL_BFF,
  timeout: 0
});

configAxios.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  const token = getCookie(COOKIE_MAIN_PORTAL);
  const configClone = { ...config } as InternalAxiosRequestConfig;

  if (token) {
    configClone.headers.Authorization = `${token}`;
  }

  return { ...configClone, metadata: { startTime: new Date() } };
});

configAxios.interceptors.response.use(
  async (response: AxiosResponse) => response,
  (error) => verifyFailedResponse(error, 'ApiUserAuth')
);

export default class ApiUserAuth {
  static get<TypeGetResponse = any>(
    relativeUrl: string,
    configs?: AxiosRequestConfig,
    apiVersion: string = API_VERSION_1
  ): Promise<AxiosResponse<TypeGetResponse>> {
    return configAxios.get(`/${apiVersion}/auth${relativeUrl}`, configs ?? {});
  }

  static post<TypePostResponse = any, TypeDataPost = any>(
    relativeUrl: string,
    data?: TypeDataPost,
    config?: AxiosRequestConfig,
    apiVersion: string = API_VERSION_1
  ): Promise<AxiosResponse<TypePostResponse>> {
    return configAxios.post(`/${apiVersion}/auth${relativeUrl}`, data ?? {}, config ?? {});
  }

  static put<TypePutResponse = any, TypeDataPut = any>(
    relativeUrl: string,
    data?: TypeDataPut,
    config?: AxiosRequestConfig,
    apiVersion: string = API_VERSION_1
  ): Promise<AxiosResponse<TypePutResponse>> {
    return configAxios.put(`/${apiVersion}/auth${relativeUrl}`, data ?? {}, config ?? {});
  }
}
