/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react/jsx-props-no-spreading */
import { ChangeEvent, useRef, useState } from 'react';
import { useController } from 'react-hook-form';
import { WarningIcon, EyeIcon, EyeCloseIcon, PadlockIcon } from '@/shared/icons';
import {
  maskMoney,
  maskDate,
  maskPhone,
  maskOnlyNumbers,
  maskNumbersWithDots,
  stringToCpfMask,
  maskPhoneNineDigits
} from '@/helpers/mask';
import useClicked from '@/hooks/useClicked';
import { inputValidator } from '@/helpers/validators/inputValidator';
import { twMerge } from 'tailwind-merge';

type MaskType =
  | 'money'
  | 'number'
  | 'numberWithDots'
  | 'cpf'
  | 'date'
  | 'phone'
  | 'phoneNine'
  | 'default'
  | 'document'
  | 'username'
  | 'email'
  | 'password';

export enum InputTextTypeEnum {
  Text = 'text',
  Number = 'number',
  Email = 'email',
  Search = 'search',
  Password = 'password',
  Hidden = 'hidden'
}

type IInputType = {
  label: string;
  type: InputTextTypeEnum;
  autoComplete?: 'on' | 'off' | 'current-password';
  name: string;
  control: any;
  placeholder: string;
  required?: boolean;
  disabled?: boolean;
  ifDisabledChangeStyles?: boolean;
  onClick?: (e: any) => void;
  onBlur?: (e: any) => void;
  maxLength?: number;
  error?: any;
  mask?: MaskType | Extract<MaskType, 'document' | 'email' | 'username'>[];
  className?: string;
  inputClass?: string;
  defaultValue?: string;
  ariaLabel?: string;
  // eslint-disable-next-line react/require-default-props
  value?: string;
  readOnly?: boolean;
};

const InputText = ({
  label,
  type,
  autoComplete = 'on',
  name,
  placeholder,
  disabled = false,
  ifDisabledChangeStyles = false,
  control,
  required = false,
  defaultValue = '',
  maxLength = null,
  onClick = () => null,
  error = null,
  mask = 'default',
  className = '',
  inputClass = '',
  readOnly = false,
  ariaLabel = '',
  ...rest
}: IInputType) => {
  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { isTouched, isDirty, invalid }
  } = useController({
    name,
    control,
    defaultValue: defaultValue || ''
  });

  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { wasClickedInside } = useClicked(wrapperRef);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(mask)) {
      mask.every((maskItem) => {
        if (inputValidator[maskItem]?.test(event.target.value)) {
          onChange(inputValidator[maskItem]?.handler(event.target.value));
          return false;
        }
        onChange(event.target.value);
        return true;
      });

      return;
    }
    const maskLiterals = {
      money: maskMoney(event.target.value),
      number: maskOnlyNumbers(event.target.value),
      numberWithDots: maskNumbersWithDots(event.target.value),
      cpf: stringToCpfMask(event.target.value),
      date: maskDate(event.target.value),
      phone: maskPhone(event.target.value),
      phoneNine: maskPhoneNineDigits(event.target.value),
      default: event.target.value
    };

    onChange(maskLiterals[mask] ?? maskLiterals.default);
  };

  const stylesIsInvalid = invalid && isTouched;
  const stylesIsValid = !invalid && isDirty && maxLength > 0 && value.length === maxLength;
  const stylesHidden = type === InputTextTypeEnum.Hidden ? 'hidden' : '';

  const showPasswordButton = () => {
    const needShowEye = type === InputTextTypeEnum.Password;
    const eyeIsVisible = !value ? 'hidden' : '';

    return (
      needShowEye && (
        <button
          type="button"
          onClick={() => setPasswordVisible((input) => !input)}
          className={`absolute right-4 top-4 z-10 opacity-90 text-black ${eyeIsVisible}`}
        >
          {passwordVisible ? <EyeIcon /> : <EyeCloseIcon />}
        </button>
      )
    );
  };

  const styleWithInputClickedOrWithValue =
    wasClickedInside || value
      ? 'top-[-12px] text-[14px] bg-white ml-4 text-gray600'
      : 'top-[0.65rem] text-[16px] bg-transparent ml-2 text-subtitleGray';

  const renderError = () =>
    error ? (
      <span role="alert" title={error} className="mt-1 text-danger flex">
        <WarningIcon /> <small className="align-middle font-bold ml-1">{error}</small>
      </span>
    ) : null;

  return (
    <div className={`${className} ${stylesHidden}`}>
      <div
        ref={wrapperRef}
        className={` relative
        bg-transparent rounded-lg border-2 w-full transition-all duration-500 ease-in-out
        ${className} ${error ? `border-red-700` : `hover:border-gray-400`}
        relative h-[48px] text-left
          ${stylesIsInvalid ? 'border-danger' : ''} ${stylesIsValid ? 'border-success' : ''}
        `}
      >
        <label className={`px-2 absolute transition-all ${styleWithInputClickedOrWithValue}`} htmlFor={name}>
          {label} {!!required && <span className="text-danger">*</span>}
        </label>

        <div className="relative h-full px-2">
          {showPasswordButton()}

          <input
            type={passwordVisible ? InputTextTypeEnum.Text : type}
            className={twMerge(
              `${inputClass} relative h-full bg-transparent bg-none font-pravalerText font-normal px-2 w-full text-subtitleGray inline-block disabled:grayHover:text-grayHover read-only:text-gray disabled:text-gray focus:bg-transparent focus:shadow-none !outline-none focus:!outline-none active:bg-transparent active:shadow-none active:outline-none autofill:bg-transparent autofill:bg-none autofill:shadow-none bg-clip-text hover:cursor-pointer`
            )}
            ref={ref}
            onClick={onClick}
            id={name}
            name={name}
            readOnly={readOnly}
            aria-invalid={error ? 'true' : 'false'}
            list={autoComplete === 'off' ? 'autocompleteOff' : ''}
            autoComplete={autoComplete}
            disabled={disabled}
            placeholder={wasClickedInside ? placeholder : ''}
            title={value && typeof value === 'string' ? `${label}: ${value}` : ''}
            value={value?.toString()}
            onChange={(event) => handleChange(event)}
            maxLength={maxLength}
            aria-label={ariaLabel}
            {...inputProps}
            {...rest}
          />
          {disabled && ifDisabledChangeStyles && type !== InputTextTypeEnum.Number && (
            <PadlockIcon className="absolute right-4 top-0 text-gray-400" />
          )}
        </div>
      </div>

      {renderError()}
    </div>
  );
};

export default InputText;
